<template>
  <div>
    <b-button-toolbar key-nav class="mb-3">
      <b-button variant="primary" size="sm" :to="{ name: 'UserRegister' }">
        <span class="fas fa-plus mr-1" /> Cadastrar Usuário
      </b-button>
    </b-button-toolbar>

    <!-- Barra de Filtros (inicia minimizada) -->
    <b-card no-body class="mb-3">
      <b-card-header
        class="d-flex justify-content-between align-items-center"
        style="cursor: pointer"
        @click="toggleFilters"
      >
        <span>Filtros</span>
        <span
          :class="showFilters ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"
        ></span>
      </b-card-header>
      <b-collapse :visible="showFilters">
        <b-card-body>
          <b-form-group label="Buscar por Nome:" label-for="filter-text">
            <b-form-input
              id="filter-text"
              v-model="filterText"
              placeholder="Digite um nome para filtrar..."
            ></b-form-input>
          </b-form-group>
          <b-form-row>
            <b-col md="6">
              <b-form-group label="Filtrar por Tipo:" label-for="filter-role">
                <b-form-select
                  id="filter-role"
                  v-model="filterRole"
                  :options="roleOptions"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Filtrar por Shopping:"
                label-for="filter-shopping"
              >
                <b-form-select
                  id="filter-shopping"
                  v-model="filterShopping"
                  :options="shoppingOptions"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-alert v-if="error" show variant="danger">
      <span class="fas fa-exclamation-triangle" /> {{ error }}
    </b-alert>

    <b-table
      ref="usersList"
      :fields="fields"
      :items="provider"
      striped
      hover
      :busy="isBusy"
    >
      <div slot="empty" class="text-center">Nenhum usuário cadastrado</div>
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="getColumnStyle(field.key)"
        />
      </template>
      <template #cell(store)="{ item: user }">
        <UserStoreNames :user="user" />
      </template>
      <template #cell(status)="{ value: status }">
        <Status :value="status" />
      </template>
      <template #cell(role)="{ item: user }">
        <UserStoreRoleNames :user="user" />
      </template>
      <template #cell(actions)="{ item }">
        <BaseListActions
          v-if="canManage(item)"
          :item="item"
          @edit="onEdit"
          @remove="onRemove"
          @updateStatus="onUpdateStatus"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import services from '@/services'
import withStatusAction from '@/mixins/withStatusAction'
import Status from '@/components/Status'
import UserStoreNames from '@/components/UserStoreNames'
import UserStoreRoleNames from '@/components/UserStoreRoleNames'
import BaseListActions from '@/components/BaseListActions'
import { parseHTTPError } from '@/helpers/errors'
import { mapGetters } from 'vuex'

// Constante com os tipos de roles disponíveis
export const ROLES = {
  ADMIN: {
    value: 'admin',
    text: 'Admin Geral'
  },
  SHOPPING: {
    value: 'shopping',
    text: 'Shopping'
  },
  EMPLOYER: {
    value: 'employer',
    text: 'Usuário'
  },
  ADMIN_FRANCHISEE: {
    value: 'franchiseeAdmin',
    text: 'Franqueado Admin'
  }
}

export default {
  name: 'UsersList',
  components: {
    Status,
    UserStoreRoleNames,
    UserStoreNames,
    BaseListActions
  },
  mixins: [withStatusAction(services.users.updateUser)],
  data() {
    return {
      isBusy: false,
      error: null,
      // Controle de exibição dos filtros
      showFilters: false,
      // Filtros
      filterText: '',
      filterRole: '',
      filterShopping: '',
      // Cache dos usuários retornados (para montar as opções de shoppings)
      allUsers: []
    }
  },
  computed: {
    fields: () => [
      { key: 'name', label: 'Nome' },
      { key: 'email', label: 'Email' },
      { key: 'store', label: 'Loja' },
      { key: 'status', label: 'Status' },
      { key: 'role', label: 'Tipo' },
      { key: 'actions', label: 'Ações' }
    ],
    ...mapGetters('store', ['isAdminFranchisee', 'currentStoreId']),
    // Opções para filtro de roles
    roleOptions() {
      return [{ value: '', text: 'Todos' }, ...Object.values(ROLES)]
    },
    // Opções para filtro de shoppings, extraídas dos usuários
    shoppingOptions() {
      const shoppingsMap = {}
      // Itera sobre o cache de usuários
      this.allUsers.forEach((user) => {
        user.userStores.forEach((us) => {
          if (us.store && us.store.id) {
            shoppingsMap[us.store.id] = us.store.name
          }
        })
      })
      const options = Object.keys(shoppingsMap).map((id) => ({
        value: id,
        text: shoppingsMap[id]
      }))
      return [{ value: '', text: 'Todos' }, ...options]
    }
  },
  watch: {
    // Sempre que algum filtro mudar, atualiza a tabela
    filterText() {
      this.refreshTable()
    },
    filterRole() {
      this.refreshTable()
    },
    filterShopping() {
      this.refreshTable()
    }
  },
  methods: {
    toggleFilters() {
      this.showFilters = !this.showFilters
    },
    // Método para disparar a atualização da tabela
    refreshTable() {
      if (this.$refs.usersList) {
        this.$refs.usersList.refresh()
      }
    },
    // Provider para o b-table: busca os usuários e aplica os filtros
    async provider() {
      this.isBusy = true
      try {
        // Busca todos os usuários
        let users = await services.users.fetchUsers()

        // Se for ADMIN_FRANCHISEE e houver currentStoreId, filtra
        if (this.isAdminFranchisee && this.currentStoreId) {
          users = users.filter((user) => {
            // Permite somente usuários que tenham exatamente 1 userStore
            if (user.userStores.length !== 1) {
              return false
            }
            const [singleUserStore] = user.userStores
            // Retorna apenas se a única loja for a currentStoreId
            // e o role for 'employer' OU 'franchiseeAdmin'
            return (
              singleUserStore.storeId === this.currentStoreId &&
              (singleUserStore.role === 'employer' ||
                singleUserStore.role === 'franchiseeAdmin')
            )
          })
        }
        // Atualiza o cache para construir as opções do filtro de shoppings
        this.allUsers = users

        // Aplica filtro por texto (nome)
        if (this.filterText) {
          const text = this.filterText.toLowerCase()
          users = users.filter((user) => user.name.toLowerCase().includes(text))
        }

        // Aplica filtro por role (tipo)
        if (this.filterRole) {
          users = users.filter((user) =>
            user.userStores.some((us) => us.role === this.filterRole)
          )
        }

        // Aplica filtro por shopping
        if (this.filterShopping) {
          // Como o v-model retorna string, converte para número, se necessário
          const shoppingId = parseInt(this.filterShopping, 10)
          users = users.filter((user) =>
            user.userStores.some((us) => us.store && us.store.id === shoppingId)
          )
        }

        return users
      } catch (error) {
        this.error = parseHTTPError(error)
        return []
      } finally {
        this.isBusy = false
      }
    },
    canManage(user) {
      if (this.isAdminFranchisee) {
        return user.userStores.some((us) => us.role === 'employer')
      }
      return true
    },
    onEdit(user) {
      const { id } = user
      this.$router.push({
        name: 'UserRegister',
        params: { id }
      })
    },
    async onRemove(user) {
      const message = `Deseja realmente remover o usuário ${user.name}?`
      const result = await this.$bvModal.msgBoxConfirm(message)
      if (result) {
        await services.users.removeUser(user.id)
        this.$refs.usersList.refresh()
        this.$root.$toast.success('Usuário removido com sucesso')
      }
    },
    async onUpdateStatus(user) {
      await this.toggleStatus(user)
      this.$refs.usersList.refresh()
    },
    getColumnStyle(field) {
      return { width: field === 'actions' ? '1%' : '' }
    }
  }
}
</script>
